export const SPORT_DISPLAY_INFO = {
	"football": {
		name: "Football",
		espnUrlId: "college-football"
	},
	"wsoccer": {
		name: "Women's Soccer",
	},
	"mbasketball": {
		name: "Men's Basketball",
		espnUrlId: "mens-college-basketball"
	},
	"wbasketball": {
		name: "Women's Basketball",
		espnUrlId: "womens-college-basketball"
	},
	"baseball": {
		name: "Baseball",
	},
	"softball": {
		name: "Softball",
	},
	"olympics": {
		name: "Olympics",
	},
};

import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';

import * as TeamTickerInfo from './TeamTickerInfo';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Box from '@mui/material/Box';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import Alert from '@mui/material/Alert';

import { At } from '@emotion-icons/bootstrap/At';
import { Googlemaps } from '@emotion-icons/simple-icons/Googlemaps';

import { BasketballBall } from '@emotion-icons/fa-solid/BasketballBall';
import { FootballBall } from '@emotion-icons/fa-solid/FootballBall';
import { BaseballBall } from '@emotion-icons/fa-solid/BaseballBall';
import { SportsSoccer } from '@emotion-icons/material/SportsSoccer';
import { WeatherPartlySunny } from '@emotion-icons/typicons/WeatherPartlySunny';

import moment from 'moment-timezone';

import
{
	APPSTATUS_ENUM,
	selectJWT,
	selectRefreshToken,

	loadTeamInfo,
	selectTeamInfo,
	selectLoadTeamInfoStatus,
	resetLoadTeamInfoStatus,

	loadPublishDataSummary,
	selectPublishDataSummary,
	selectLoadPublishDataSummaryStatus,
	resetLoadPublishDataSummaryStatus,
} from '../store/appSlice';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
		fontSize: 16,
		fontWeight: 700,
		padding: 8,
		paddingLeft: 12,
		paddingRight: 12,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		padding: 8,
		paddingLeft: 12,
		paddingRight: 12,
	},
	[`&.${tableCellClasses.footer}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
		fontSize: 16,
		fontWeight: 700,
		padding: 8,
		paddingLeft: 12,
		paddingRight: 12,
	},
}));

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

const SPORTS_ORDER = ["football", "wsoccer", "msoccer", "mbasketball", "wbasketball", "mhockey", "whockey", "baseball", "softball", null];

const PLAYLIST_DEFAULT_ENV = "prod";

const ERROROBJ_EMPTY = { severity: "none", message: "" };

function LoadingSpinner()
{
	return <>
		<Stack spacing={2} alignItems="center" justifyContent="center" mt={4}>
			<CircularProgress size={40} color="primary" />
			<Typography variant={"subtitle1"} mb={2}>Loading&hellip;</Typography>
		</Stack>
	</>;
};

function fixStatsLine(str)
{
	return str.replace(/&thinsp;/g, ' ');
}

function getSportIcon(sportId)
{
	let ret = <></>;
	switch (sportId)
	{
		case "mbasketball":
		case "wbasketball":
			ret = <BasketballBall style={{ paddingRight: 8 }} width={16} />
			break;
		case "football":
			ret = <FootballBall style={{ paddingRight: 8 }} width={16} />
			break;
		case "baseball":
		case "softball":
			ret = <BaseballBall style={{ paddingRight: 8 }} width={16} />
			break;
		case "wsoccer":
		case "msoccer":
			ret = <SportsSoccer style={{ paddingRight: 8 }} width={16} />
			break;


	}

	return ret;
}

function GamesSummary()
{
	const dispatch = useDispatch();

	const theme = useTheme();

	const [refreshRunning, setRefreshRunning] = React.useState("");
	const [currEnv, setCurrEnv] = React.useState(PLAYLIST_DEFAULT_ENV);
	const [errorObj, setErrorObj] = React.useState(ERROROBJ_EMPTY);

	const jwt = useSelector(selectJWT);

	const loadPublishDataSummaryStatus = useSelector(selectLoadPublishDataSummaryStatus);

	const teamInfo = useSelector(selectTeamInfo);
	const publishDataSummary = useSelector(selectPublishDataSummary);

	const loadInfoRunning = (loadPublishDataSummaryStatus.status === APPSTATUS_ENUM.LOADING);

	const allLoaded = (teamInfo.loaded && publishDataSummary.loaded);

	React.useEffect(() =>
	{
		let contextObj = { jwt };


		//dispatch(loadTeamInfo({ ...contextObj }));
		dispatch(loadPublishDataSummary({ ...contextObj, env: currEnv }));

	}, []);

	React.useEffect(() =>
	{
		if (loadPublishDataSummaryStatus.status === APPSTATUS_ENUM.SUCCESS)
		{
			dispatch(resetLoadPublishDataSummaryStatus());
		}
		else if (loadPublishDataSummaryStatus.status === APPSTATUS_ENUM.FAILED)
		{
			setErrorObj({ severity: "error", message: loadPublishDataSummaryStatus.errorMsg });

			dispatch(resetLoadPublishDataSummaryStatus());
		}
	}, [loadPublishDataSummaryStatus]);

	const isRefreshAllSpinning = () =>
	{
		return (loadInfoRunning && refreshRunning == "all");
	}

	const handleRefreshAll = (env) =>
	{
		let contextObj = { jwt };

		setRefreshRunning("all");
		setErrorObj(ERROROBJ_EMPTY);

		try
		{
			dispatch(loadPublishDataSummary({ ...contextObj, env: env }));
		}
		catch (err)
		{
			console.log("ERROR: handleRefresh: " + err);
		}
	}

	return <>
		{
			allLoaded ?
				<>
					<div style={{ width: "100%", paddingRight: 10, paddingLeft: 10 }}>
						<div key={"buttons"} style={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
							<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
								<InputLabel id="demo-select-small-label">Environment</InputLabel>
								<Select
									key={"Select-contentSet"}
									value={currEnv}
									label="selectContent"
									labelId="demo-select-small-label"
									onChange={(event) =>
									{
										setCurrEnv(event.target.value);
										handleRefreshAll(event.target.value);
									}}
								>
									<MenuItem key={"prod"} value={"prod"}>{"Production"}</MenuItem>
									<MenuItem key={"stage"} value={"stage"}>{"Staging"}</MenuItem>
								</Select>
							</FormControl>
							<div style={{ height: "80%" }}>
								<Button onClick={() => { handleRefreshAll(currEnv); }} variant="contained">
									<>Refresh</>
									{
										isRefreshAllSpinning() &&
										<>&nbsp;&nbsp;&nbsp;<CircularProgress size={20} style={{ color: 'white' }} /></>
									}
								</Button>
							</div>
						</div>
						{
							errorObj.severity != "none" &&
							<div key={"error-message"} style={{ marginTop: 10 }}>
								<Alert severity="error" onClose={() => { setErrorObj(ERROROBJ_EMPTY); }}>{errorObj.message}</Alert>
							</div>
						}
						<div key={"maincontent"} style={{ paddingTop: 10, paddingBottom: 20 }}>
							{
								Object.keys(teamInfo.data?.teamInfo?.allItems).map((teamId, i) =>
								//Object.keys(publishDataSummary.data.summary.teams).map((teamId, i) =>
								{
									if (publishDataSummary.data.summary.teams.hasOwnProperty(teamId))
									{
										let summaryTeamInfo = publishDataSummary.data.summary.teams[teamId];
										let team = teamInfo.data?.teamInfo?.allItems[teamId];

										return <>
											<div className="gamesummary-team-name">{team.name}</div>
											<div>
												{
													// SPORTS_ORDER
													//Object.keys(summaryTeamInfo.sports).map((sportId, j) =>
													SPORTS_ORDER.map((sportId, j) =>
													{
														if (summaryTeamInfo.sports.hasOwnProperty(sportId))
														{
															let summarySportInfo = summaryTeamInfo.sports[sportId];

															return <>
																<div>
																	{
																		summarySportInfo.games.map((gameInfo, k) =>
																		{
																			let startDate = new Date(gameInfo.startTime);

																			let startTimeMoment = moment.tz(startDate, timeZone);
																			let startTimeStr = startTimeMoment.format("ddd, MMM Do h:mm A") + (startTimeMoment.isDST() ? " EDT" : " EST");

																			//https://www.visualcrossing.com/weather-forecast/40.7128,-74.0060

																			return <>
																				<div className="gamesummary-game-container">
																					<Paper elevation={5} >
																						<div className="gamesummary-game-inner-container">
																							<div className="gamesummary-title-sport">{getSportIcon(sportId)}{TeamTickerInfo.SPORT_DISPLAY_INFO[sportId].name}</div>

																							<div className="gamesummary-title-box">
																								<div className="gamesummary-title-teams"><span>{gameInfo.awayTeam}</span><At style={{ paddingLeft: 4, paddingRight: 4 }} width="20" /><span>{gameInfo.homeTeam}</span></div>
																								{
																									gameInfo.gameLocationCoordinates &&
																									<div><a target="_blank" href={"http://www.google.com/maps/place/" + gameInfo.gameLocationCoordinates}><Googlemaps color="#e52534" width="24" /></a></div>
																								}
																								{
																									gameInfo.gameLocationCoordinates &&
																									<div><a target="_blank" href={"https://www.visualcrossing.com/weather-forecast/" + gameInfo.gameLocationCoordinates + "/us"}><WeatherPartlySunny color="#e52534" width="24" /></a></div>
																								}
																								{
																									TeamTickerInfo.SPORT_DISPLAY_INFO[sportId].espnUrlId && gameInfo.gameId &&
																									<div><a target="_blank" href={"https://www.espn.com/" + TeamTickerInfo.SPORT_DISPLAY_INFO[sportId].espnUrlId + "/game/_/gameId/" + gameInfo.gameId}>
																										<div style={{ overflow: "hidden", height: "20px" }}>
																											<img style={{ marginTop: -18, color: "#e52534" }} width="60" src={process.env.PUBLIC_URL + '/espn-1.svg'} />
																										</div>
																									</a></div>
																								}
																							</div>
																							<div className="gamesummary-time-box">{startTimeStr}&nbsp;{gameInfo.whereToWatch}</div>
																							{
																								gameInfo?.showWeather === true &&
																								<div className="gamesummary-weather-box">
																									<strong>Weather</strong> {gameInfo.weatherTemplate}, temperature {gameInfo.weatherStatLeft.replace("&deg;", "°")}, precipitation {gameInfo.weatherStatRight}
																								</div>
																							}
																							<div className="gamesummary-series-stats-container">
																								{
																									gameInfo.seriesStats.map((statInfo, l) =>
																									{
																										return <>
																											<div className="gamesummary-series-stat-container">
																												{
																													statInfo.map((line, m) =>
																													{
																														return <>
																															{
																																line.content.length > 0 &&
																																<div className={"gamesummary-series-stat-line" + (line.highlight ? " gamesummary-series-stat-line-highlight" : "")}>{fixStatsLine(line.content)}</div>
																															}
																														</>
																													})
																												}
																											</div>
																										</>
																									})
																								}
																							</div>
																						</div>
																					</Paper>
																				</div>
																			</>
																		})
																	}
																</div >
															</>

														}

													})
												}
											</div >
										</>

									}

								})
							}
							{
								false &&
								<><pre><code>{JSON.stringify(publishDataSummary, null, 2)}</code></pre><br /></>
							}
						</div>

					</div >
				</>
				:
				<><LoadingSpinner /></>
		}
	</>;
}

export default GamesSummary;

import { isExpired, decodeToken } from "react-jwt";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import * as FakePublishResults from '../utils/FakePublishResults';

function getResetStatus(status = APPSTATUS_ENUM.IDLE, statusObj = { errorCode: 0, errorMsg: undefined })
{
	return {
		status,
		...statusObj
	}
}

const getInitialState = () =>
{
	return {

		jwt: undefined,
		jwtObj: {},

		refreshToken: undefined,

		contentTab: "",

		teamInfo: {
			teamInfo: {
				teams: {},
				components: {},
				allItems: {}
			}
		},

		playlistInfo: {

		},

		publishDataSummary: {

		},

		modelInfo: {
			modelInfo: {}
		},

		userDeviceGroups: {
			deviceGroups: []
		},

		sheetIdMap: {},
		teamInfoLoaded: false,
		playlistInfoLoaded: false,
		publishDataSummaryLoaded: false,
		modelInfoLoaded: false,
		userDeviceGroupsLoaded: false,
		sheetsRevisionInfo: {
			sheetRevisions: {}
		},
		sheetsRevisionInfoLoaded: false,
		teamFileInfo: {},
		teamFileInfoLoaded: false,
		publishResultsInfo: {},

		signInUserStatus: getResetStatus(),
		appLoadUserStatus: getResetStatus(),
		googleCodeToRefreshTokenStatus: getResetStatus(),
		loadTeamInfoStatus: getResetStatus(),
		loadPlaylistInfoStatus: getResetStatus(),
		loadPublishDataSummaryStatus: getResetStatus(),
		loadModelInfoStatus: getResetStatus(),
		loadUserDeviceGroupsStatus: getResetStatus(),
		loadSheetsRevisionInfoStatus: getResetStatus(),
		loadTeamFileInfoStatus: getResetStatus(),
		saveGameDataStatus: getResetStatus(),
		updatetUserDeviceInfoStatus: getResetStatus(),
		deleteGameDataStatus: getResetStatus(),
		getGameDataStatus: getResetStatus(),
		publishGameDataStatus: getResetStatus(),
	};
};

export const APPSTATUS_ENUM = {
	"IDLE": "IDLE",
	"LOADING": "LOADING",
	"SUCCESS": "SUCCESS",
	"FAILED": "FAILED",
};

const LOCALSTORAGE_USER_JWT = "app.user.jwt";
const LOCALSTORAGE_USER_REFRESHTOKEN = "app.user.refreshToken";

function timeout(ms)
{
	return new Promise(resolve => setTimeout(resolve, ms));
}

async function handleError(json, response)
{
	try
	{
		let jsonLocal = await response.json();

		json.status.errorCode = jsonLocal.code ? jsonLocal.code : 500;
		json.status.errorMsg = jsonLocal.message ? jsonLocal.message : "Unknown Error";
	}
	catch (err)
	{
		console.log("ERROR: handleError: " + err);
		console.log(err.stack);
	}
}

export const loadTeamInfo = createAsyncThunk(
	'app/loadTeamInfo',

	async (callData) =>
	{
		let json = {
			status: {
				errorCode: 500,
				errorMsg: "Unknown Error"
			}
		};

		let bodyObj = { command: "getTeamInfo" };

		try
		{
			let fetchOptions = {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + callData.jwt
				},

				body: JSON.stringify(bodyObj)
			};

			let response = await fetch(process.env.REACT_APP_PUBLISHER_API_URL + '/control', fetchOptions);

			if (response.status >= 200 && response.status < 300)
			{
				json.response = await response.json();
				json.status.errorCode = response.status;
			}
			else
			{
				await handleError(json, response);
			}
		}
		catch (err)
		{
			console.log("ERROR: appSignInUser: " + err);
			console.log(err.stack);
		}

		return json;
	}
);

export const loadPlaylistInfo = createAsyncThunk(
	'app/loadPlaylistInfo',

	async (callData) =>
	{
		let json = {
			status: {
				errorCode: 500,
				errorMsg: "Unknown Error"
			}
		};

		let bodyObj = { command: "getPlaylistInfo", env: callData.env, playlists: callData.playlists };

		try
		{
			let fetchOptions = {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + callData.jwt
				},

				body: JSON.stringify(bodyObj)
			};

			let response = await fetch(process.env.REACT_APP_PUBLISHER_API_URL + '/control', fetchOptions);

			if (response.status >= 200 && response.status < 300)
			{
				json.response = await response.json();
				json.status.errorCode = response.status;
			}
			else
			{
				await handleError(json, response);
			}
		}
		catch (err)
		{
			console.log("ERROR: appSignInUser: " + err);
			console.log(err.stack);
		}

		return json;
	}
);

export const loadPublishDataSummary = createAsyncThunk(
	'app/loadPublishDataSummary',

	async (callData) =>
	{
		let json = {
			status: {
				errorCode: 500,
				errorMsg: "Unknown Error"
			}
		};

		let bodyObj = { command: "getPublishDataSummary", env: callData.env };

		try
		{
			let fetchOptions = {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + callData.jwt
				},

				body: JSON.stringify(bodyObj)
			};

			let response = await fetch(process.env.REACT_APP_PUBLISHER_API_URL + '/control', fetchOptions);

			if (response.status >= 200 && response.status < 300)
			{
				json.response = await response.json();
				json.env = callData.env;
				json.status.errorCode = response.status;
			}
			else
			{
				await handleError(json, response);
			}
		}
		catch (err)
		{
			console.log("ERROR: appSignInUser: " + err);
			console.log(err.stack);
		}

		return json;
	}
);

export const loadModelInfo = createAsyncThunk(
	'app/loadModelInfo',

	async (callData) =>
	{
		let json = {
			status: {
				errorCode: 500,
				errorMsg: "Unknown Error"
			}
		};

		let bodyObj = { command: "getModelInfo" };

		try
		{
			let fetchOptions = {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + callData.jwt
				},

				body: JSON.stringify(bodyObj)
			};

			let response = await fetch(process.env.REACT_APP_PUBLISHER_API_URL + '/control', fetchOptions);

			if (response.status >= 200 && response.status < 300)
			{
				json.response = await response.json();
				json.status.errorCode = response.status;
			}
			else
			{
				await handleError(json, response);
			}
		}
		catch (err)
		{
			console.log("ERROR: appSignInUser: " + err);
			console.log(err.stack);
		}

		return json;
	}
);

export const loadUserDeviceGroups = createAsyncThunk(
	'app/loadUserDeviceGroups',

	async (callData) =>
	{
		let json = {
			status: {
				errorCode: 500,
				errorMsg: "Unknown Error"
			}
		};

		let bodyObj = { command: "getUserDeviceGroups" };

		try
		{
			let fetchOptions = {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + callData.jwt
				},

				body: JSON.stringify(bodyObj)
			};

			let response = await fetch(process.env.REACT_APP_PUBLISHER_API_URL + '/control', fetchOptions);

			if (response.status >= 200 && response.status < 300)
			{
				json.response = await response.json();
				json.status.errorCode = response.status;
			}
			else
			{
				await handleError(json, response);
			}
		}
		catch (err)
		{
			console.log("ERROR: appSignInUser: " + err);
			console.log(err.stack);
		}

		return json;
	}
);

export const loadSheetsRevisionInfo = createAsyncThunk(
	'app/loadSheetsRevisionInfo',

	async (callData) =>
	{
		let json = {
			status: {
				errorCode: 500,
				errorMsg: "Unknown Error"
			}
		};

		let bodyObj = { command: "getSheetsRevisionInfo", refreshToken: callData.refreshToken };

		if (callData.sheetIds?.length > 0)
		{
			bodyObj.sheetIds = callData.sheetIds;
		}

		try
		{
			let fetchOptions = {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + callData.jwt
				},

				body: JSON.stringify(bodyObj)
			};

			let response = await fetch(process.env.REACT_APP_PUBLISHER_API_URL + '/control', fetchOptions);

			if (response.status >= 200 && response.status < 300)
			{
				json.response = await response.json();
				json.status.errorCode = response.status;
			}
			else
			{
				await handleError(json, response);
			}
		}
		catch (err)
		{
			console.log("ERROR: appSignInUser: " + err);
			console.log(err.stack);
		}

		return json;
	}
);

export const loadTeamFileInfo = createAsyncThunk(
	'app/loadTeamFileInfo',

	async (callData) =>
	{
		let json = {
			status: {
				errorCode: 500,
				errorMsg: "Unknown Error"
			}
		};

		let bodyObj = { command: "getTeamFileInfo", env: callData.env };

		try
		{
			let fetchOptions = {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + callData.jwt
				},

				body: JSON.stringify(bodyObj)
			};

			let response = await fetch(process.env.REACT_APP_PUBLISHER_API_URL + '/control', fetchOptions);

			if (response.status >= 200 && response.status < 300)
			{
				json.response = await response.json();
				json.status.errorCode = response.status;
			}
			else
			{
				await handleError(json, response);
			}
		}
		catch (err)
		{
			console.log("ERROR: appSignInUser: " + err);
			console.log(err.stack);
		}

		return json;
	}
);

export const saveGameData = createAsyncThunk(
	'app/saveGameData',

	async (callData) =>
	{
		let json = {
			status: {
				errorCode: 500,
				errorMsg: "Unknown Error"
			}
		};

		let bodyObj = {
			command: "saveGameData",
			refreshToken: callData.refreshToken,
			env: callData.env,
			sheetId: callData.sheetId,
			dataType: callData.dataType,
			refreshAllInEnv: callData.refreshAllInEnv
		};

		try
		{
			let fetchOptions = {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + callData.jwt
				},

				body: JSON.stringify(bodyObj)
			};

			let response = await fetch(process.env.REACT_APP_PUBLISHER_API_URL + '/control', fetchOptions);

			if (response.status >= 200 && response.status < 300)
			{
				json.response = await response.json();
				json.status.errorCode = response.status;
			}
			else
			{
				await handleError(json, response);
			}
		}
		catch (err)
		{
			console.log("ERROR: appSignInUser: " + err);
			console.log(err.stack);
		}

		return json;
	}
);

export const updatetUserDeviceInfo = createAsyncThunk(
	'app/updatetUserDeviceInfo',

	async (callData) =>
	{
		let json = {
			status: {
				errorCode: 500,
				errorMsg: "Unknown Error"
			}
		};

		let bodyObj = {
			command: "updatetUserDeviceInfo",
			deviceUpdateList: callData.deviceUpdateList,
		};

		try
		{
			let fetchOptions = {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + callData.jwt
				},

				body: JSON.stringify(bodyObj)
			};

			let response = await fetch(process.env.REACT_APP_PUBLISHER_API_URL + '/control', fetchOptions);

			if (response.status >= 200 && response.status < 300)
			{
				json.response = await response.json();
				json.status.errorCode = response.status;
			}
			else
			{
				await handleError(json, response);
			}
		}
		catch (err)
		{
			console.log("ERROR: appSignInUser: " + err);
			console.log(err.stack);
		}

		return json;
	}
);

export const deleteGameData = createAsyncThunk(
	'app/deleteGameData',

	async (callData) =>
	{
		let json = {
			status: {
				errorCode: 500,
				errorMsg: "Unknown Error"
			}
		};

		let bodyObj = {
			command: "deleteGameData",
			env: callData.env,
			sheetId: callData.sheetId,
		};

		try
		{
			let fetchOptions = {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + callData.jwt
				},

				body: JSON.stringify(bodyObj)
			};

			let response = await fetch(process.env.REACT_APP_PUBLISHER_API_URL + '/control', fetchOptions);

			if (response.status >= 200 && response.status < 300)
			{
				json.response = await response.json();
				json.status.errorCode = response.status;
			}
			else
			{
				await handleError(json, response);
			}
		}
		catch (err)
		{
			console.log("ERROR: appSignInUser: " + err);
			console.log(err.stack);
		}

		return json;
	}
);

export const getGameData = createAsyncThunk(
	'app/getGameData',

	async (callData) =>
	{
		let json = {
			status: {
				errorCode: 500,
				errorMsg: "Unknown Error"
			}
		};

		let bodyObj = {
			command: "getGameData",
			refreshToken: callData.refreshToken,
			env: callData.env,
			sheetId: callData.sheetId,
			dataType: callData.dataType
		};

		try
		{
			let fetchOptions = {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + callData.jwt
				},

				body: JSON.stringify(bodyObj)
			};

			let response = await fetch(process.env.REACT_APP_PUBLISHER_API_URL + '/control', fetchOptions);

			if (response.status >= 200 && response.status < 300)
			{
				json.response = await response.json();
				json.status.errorCode = response.status;
			}
			else
			{
				await handleError(json, response);
			}
		}
		catch (err)
		{
			console.log("ERROR: appSignInUser: " + err);
			console.log(err.stack);
		}

		return json;
	}
);

export const publishGameData = createAsyncThunk(
	'app/publishGameData',

	async (callData) =>
	{
		let json = {
			status: {
				errorCode: 500,
				errorMsg: "Unknown Error"
			}
		};

		let bodyObj = {
			command: "processSignContent",
			refreshToken: callData.refreshToken,
			env: callData.env,
		};

		try
		{
			let fetchOptions = {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + callData.jwt
				},

				body: JSON.stringify(bodyObj)
			};

			let response = await fetch(process.env.REACT_APP_PUBLISHER_API_URL + '/control', fetchOptions);

			if (response.status >= 200 && response.status < 300)
			{
				json.response = await response.json();
				json.status.errorCode = response.status;
			}
			else
			{
				await handleError(json, response);
			}
		}
		catch (err)
		{
			console.log("ERROR: appSignInUser: " + err);
			console.log(err.stack);
		}

		return json;
	}
);

export const appAppLoadUser = createAsyncThunk(
	'app/appLoadUser',

	async () =>
	{
		let json = {
			status: {
				errorCode: 500,
				errorMsg: "Unknown Error"
			}
		};

		try
		{
			let jwtLocal = localStorage.getItem(LOCALSTORAGE_USER_JWT);
			let refreshTokenLocal = localStorage.getItem(LOCALSTORAGE_USER_REFRESHTOKEN);

			//console.log("appAppLoadUser: jwt:" + jwtLocal + " refreshToken:" + refreshTokenLocal);

			if (jwtLocal)
			{
				let fetchOptions = {
					method: 'POST',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
					},

					body: JSON.stringify({ jwt: jwtLocal })
				};

				let response = await fetch(process.env.REACT_APP_PUBLISHER_API_URL + '/auth', fetchOptions);

				if (response.status >= 200 && response.status < 300)
				{
					json.response = await response.json();

					if (refreshTokenLocal)
					{
						json.response.refreshToken = refreshTokenLocal;
					}

					json.status.errorCode = response.status;
				}
				else
				{
					localStorage.removeItem(LOCALSTORAGE_USER_JWT);
					localStorage.removeItem(LOCALSTORAGE_USER_REFRESHTOKEN);
				}
			}
			else
			{
				localStorage.removeItem(LOCALSTORAGE_USER_JWT);
				localStorage.removeItem(LOCALSTORAGE_USER_REFRESHTOKEN);
			}
		}
		catch (err)
		{
			console.log("ERROR: appSignInUser: " + err);
			console.log(err.stack);
		}

		return json;
	}
);

export const appSignInUser = createAsyncThunk(
	'app/signIn',


	async (signInData) =>
	{
		let json = {
			status: {
				errorCode: 500,
				errorMsg: "Unknown Error"
			}
		};

		try
		{
			let fetchOptions = {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},

				body: JSON.stringify({ username: signInData.username, password: signInData.password })
			};

			let response = await fetch(process.env.REACT_APP_PUBLISHER_API_URL + '/auth', fetchOptions);

			if (response.status >= 200 && response.status < 300)
			{
				json.response = await response.json();
				json.status.errorCode = response.status;
			}
			else if (response.status >= 400 && response.status < 500)
			{
				json.status.errorCode = response.status;
				json.status.errorMsg = "E-mail or password is incorrect";
			}
		}
		catch (err)
		{
			console.log("ERROR: appSignInUser: " + err);
			console.log(err.stack);
		}

		return json;
	}
);

export const appGoogleCodeToRefreshToken = createAsyncThunk(
	'app/googleCodeToRefreshToken',

	async (tokenData) =>
	{
		let json = {
			status: {
				errorCode: 500,
				errorMsg: "Unknown Error"
			}
		};

		try
		{
			let fetchOptions = {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + tokenData.jwt
				},

				body: JSON.stringify({ command: "getRefreshToken", refreshCode: tokenData.refreshCode })
			};

			let response = await fetch(process.env.REACT_APP_PUBLISHER_API_URL + '/authGoogle', fetchOptions);

			if (response.status >= 200 && response.status < 300)
			{
				json.response = await response.json();
				json.status.errorCode = response.status;
			}
			else
			{
				await handleError(json, response);
			}
		}
		catch (err)
		{
			console.log("ERROR: appGoogleCodeToRefreshToken: " + err);
			console.log(err.stack);
		}

		return json;
	}
);

function createSheetIdMap(teamInfoMap)
{
	let sheetIds = {};

	for (let teamId in teamInfoMap.teams)
	{
		let team = teamInfoMap.teams[teamId];
		for (let sportId in team.sports)
		{
			let sport = team.sports[sportId];

			sheetIds[sport.sheetId] = { team: teamId, sport: sportId };
		}
	}

	return sheetIds;
}

export const appSlice = createSlice({
	name: 'app',
	initialState: getInitialState(),
	reducers: {
		signOutUser: (state, action) =>
		{
			let initState = getInitialState();

			localStorage.removeItem(LOCALSTORAGE_USER_JWT);
			localStorage.removeItem(LOCALSTORAGE_USER_REFRESHTOKEN);

			state.jwt = undefined;
			state.refreshToken = undefined;
			state.teamInfo = initState.teamInfo;
			state.playlistInfo = initState.playlistInfo;
			state.publishDataSummary = initState.publishDataSummary;
			state.modelInfo = initState.modelInfo;
			state.userDeviceGroups = initState.userDeviceGroups;
			state.sheetIdMap = initState.sheetIdMap;
			state.teamInfoLoaded = false;
			state.playlistInfoLoaded = false;
			state.publishDataSummaryLoaded = false;
			state.modelInfoLoaded = false;
			state.userDeviceGroupsLoaded = false;
			state.sheetsRevisionInfo = initState.sheetsRevisionInfo;
			state.sheetsRevisionInfoLoaded = false;
			state.teamFileInfo = initState.teamFileInfo;
			state.teamFileInfoLoaded = false;
		},
		clearJWT: (state, action) =>
		{
			localStorage.removeItem(LOCALSTORAGE_USER_JWT);
			state.jwt = undefined;
			state.jwtObj = {};
		},
		setContentTab: (state, action) =>
		{
			state.contentTab = action.payload.value;
		},
		setRefreshToken: (state, action) =>
		{
			localStorage.setItem(LOCALSTORAGE_USER_JWT, action.payload.value);
			state.refreshToken = action.payload.value;
		},
		clearRefreshToken: (state, action) =>
		{
			localStorage.removeItem(LOCALSTORAGE_USER_REFRESHTOKEN);
			state.refreshToken = undefined;
		},
		clearPublishResultsInfo: (state, action) =>
		{
			localStorage.removeItem(LOCALSTORAGE_USER_REFRESHTOKEN);
			state.publishResultsInfo = {};
		},
		resetSignInUserStatus: (state, action) =>
		{
			state.signInUserStatus = getResetStatus();
		},
		resetAppLoadUserStatus: (state, action) =>
		{
			state.appLoadUserStatus = getResetStatus();
		},
		resetGoogleCodeToRefreshTokenStatus: (state, action) =>
		{
			state.googleCodeToRefreshTokenStatus = getResetStatus();
		},

		resetLoadTeamInfoStatus: (state, action) =>
		{
			state.loadTeamInfoStatus = getResetStatus();
		},
		resetLoadPlaylistInfoStatus: (state, action) =>
		{
			state.loadPlaylistInfoStatus = getResetStatus();
		},
		resetLoadPublishDataSummaryStatus: (state, action) =>
		{
			state.loadPublishDataSummaryStatus = getResetStatus();
		},
		resetLoadModelInfoStatus: (state, action) =>
		{
			state.loadModelInfoStatus = getResetStatus();
		},
		resetLoadUserDeviceGroupsStatus: (state, action) =>
		{
			state.loadUserDeviceGroupsStatus = getResetStatus();
		},
		resetLoadSheetsRevisionInfoStatus: (state, action) =>
		{
			state.loadSheetsRevisionInfoStatus = getResetStatus();
		},
		resetLoadTeamFileInfoStatus: (state, action) =>
		{
			state.loadTeamFileInfoStatus = getResetStatus();
		},
		resetSaveGameDataStatus: (state, action) =>
		{
			state.saveGameDataStatus = getResetStatus();
		},
		resetUpdatetUserDeviceInfoStatus: (state, action) =>
		{
			state.updatetUserDeviceInfoStatus = getResetStatus();
		},
		resetDeleteGameDataStatus: (state, action) =>
		{
			state.deleteGameDataStatus = getResetStatus();
		},
		resetGetGameDataStatus: (state, action) =>
		{
			state.getGameDataStatus = getResetStatus();
		},
		resetPublishGameDataStatus: (state, action) =>
		{
			state.publishGameDataStatus = getResetStatus();
		},

	},
	extraReducers(builder)
	{
		builder
			.addCase(appSignInUser.pending, (state, action) =>
			{
				state.signInUserStatus = getResetStatus(APPSTATUS_ENUM.LOADING);
			})
			.addCase(appSignInUser.fulfilled, (state, action) =>
			{
				if (action.payload?.response?.jwt)
				{
					localStorage.setItem(LOCALSTORAGE_USER_JWT, action.payload?.response?.jwt);

					state.jwt = action.payload?.response?.jwt;
					state.jwtObj = decodeToken(action.payload?.response?.jwt);

					state.signInUserStatus = getResetStatus(APPSTATUS_ENUM.SUCCESS);
				}
				else
				{
					state.signInUserStatus = getResetStatus(APPSTATUS_ENUM.FAILED, action.payload.status);
				}
			})
			.addCase(appSignInUser.rejected, (state, action) =>
			{
				state.appLoadUserStatus = getResetStatus(APPSTATUS_ENUM.FAILED);
			})

			.addCase(appAppLoadUser.pending, (state, action) =>
			{
				state.appLoadUserStatus = getResetStatus(APPSTATUS_ENUM.LOADING);
			})
			.addCase(appAppLoadUser.fulfilled, (state, action) =>
			{
				if (action.payload?.response?.jwt)
				{
					localStorage.setItem(LOCALSTORAGE_USER_JWT, action.payload?.response?.jwt);

					state.jwt = action.payload?.response?.jwt;
					state.jwtObj = decodeToken(action.payload?.response?.jwt);

					if (action.payload?.response?.refreshToken)
					{
						localStorage.setItem(LOCALSTORAGE_USER_REFRESHTOKEN, action.payload?.response?.refreshToken);
						state.refreshToken = action.payload?.response?.refreshToken;
					}

					state.appLoadUserStatus = getResetStatus(APPSTATUS_ENUM.SUCCESS);
				}
				else
				{
					state.appLoadUserStatus = getResetStatus(APPSTATUS_ENUM.FAILED, action.payload.status);
				}
			})
			.addCase(appAppLoadUser.rejected, (state, action) =>
			{
				state.appLoadUserStatus = getResetStatus(APPSTATUS_ENUM.FAILED);
			})

			.addCase(appGoogleCodeToRefreshToken.pending, (state, action) =>
			{
				state.googleCodeToRefreshTokenStatus = getResetStatus(APPSTATUS_ENUM.LOADING);
			})
			.addCase(appGoogleCodeToRefreshToken.fulfilled, (state, action) =>
			{
				if (action.payload?.response?.refreshToken)
				{
					localStorage.setItem(LOCALSTORAGE_USER_REFRESHTOKEN, action.payload?.response?.refreshToken);

					//console.log("refershToken: " + action.payload?.response?.refreshToken);

					state.refreshToken = action.payload?.response?.refreshToken;
					state.googleCodeToRefreshTokenStatus = getResetStatus(APPSTATUS_ENUM.SUCCESS);
				}
				else
				{
					state.googleCodeToRefreshTokenStatus = getResetStatus(APPSTATUS_ENUM.FAILED, action.payload.status);
				}

			})
			.addCase(appGoogleCodeToRefreshToken.rejected, (state, action) =>
			{
				state.googleCodeToRefreshTokenStatus = getResetStatus(APPSTATUS_ENUM.FAILED);
			})

			.addCase(loadTeamInfo.pending, (state, action) =>
			{
				state.loadTeamInfoStatus = getResetStatus(APPSTATUS_ENUM.LOADING);
			})
			.addCase(loadTeamInfo.fulfilled, (state, action) =>
			{
				if (action.payload?.response)
				{
					let newTeamInfo = action.payload?.response;

					newTeamInfo.teamInfo.allItems = {};
					for (let id in newTeamInfo.teamInfo.teams)
					{
						newTeamInfo.teamInfo.allItems[id] = newTeamInfo.teamInfo.teams[id];
					}
					for (let id in newTeamInfo.teamInfo.components)
					{
						newTeamInfo.teamInfo.allItems[id] = newTeamInfo.teamInfo.components[id];
					}

					state.teamInfo = newTeamInfo;

					state.sheetIdMap = createSheetIdMap(state.teamInfo);
					state.teamInfoLoaded = true;
					state.loadTeamInfoStatus = getResetStatus(APPSTATUS_ENUM.SUCCESS);
				}
				else
				{
					state.loadTeamInfoStatus = getResetStatus(APPSTATUS_ENUM.FAILED, action.payload.status);
				}
			})
			.addCase(loadTeamInfo.rejected, (state, action) =>
			{
				state.loadTeamInfoStatus = getResetStatus(APPSTATUS_ENUM.FAILED);
			})

			.addCase(loadPlaylistInfo.pending, (state, action) =>
			{
				state.loadPlaylistInfoStatus = getResetStatus(APPSTATUS_ENUM.LOADING);
			})
			.addCase(loadPlaylistInfo.fulfilled, (state, action) =>
			{
				if (action.payload?.response)
				{
					state.playlistInfo = action.payload?.response;
					state.playlistInfoLoaded = true;
					state.loadPlaylistInfoStatus = getResetStatus(APPSTATUS_ENUM.SUCCESS);
				}
				else
				{
					state.loadPlaylistInfoStatus = getResetStatus(APPSTATUS_ENUM.FAILED, action.payload.status);
				}
			})
			.addCase(loadPlaylistInfo.rejected, (state, action) =>
			{
				state.loadPlaylistInfoStatus = getResetStatus(APPSTATUS_ENUM.FAILED);
			})


			.addCase(loadPublishDataSummary.pending, (state, action) =>
			{
				state.loadPublishDataSummaryStatus = getResetStatus(APPSTATUS_ENUM.LOADING);
			})
			.addCase(loadPublishDataSummary.fulfilled, (state, action) =>
			{
				if (action.payload?.response)
				{
					state.publishDataSummary = action.payload?.response;
					state.publishDataSummaryLoaded = true;
					state.loadPublishDataSummaryStatus = getResetStatus(APPSTATUS_ENUM.SUCCESS);
				}
				else
				{
					state.loadPublishDataSummaryStatus = getResetStatus(APPSTATUS_ENUM.FAILED, action.payload.status);
				}
			})
			.addCase(loadPublishDataSummary.rejected, (state, action) =>
			{
				state.loadPublishDataSummaryStatus = getResetStatus(APPSTATUS_ENUM.FAILED);
			})



			.addCase(loadModelInfo.pending, (state, action) =>
			{
				state.loadModelInfoStatus = getResetStatus(APPSTATUS_ENUM.LOADING);
			})
			.addCase(loadModelInfo.fulfilled, (state, action) =>
			{
				if (action.payload?.response)
				{
					let newModelInfo = {
						modelInfo: action.payload?.response.modelInfo,
						selectList: []
					};

					for (let key in newModelInfo.modelInfo)
					{
						let model = newModelInfo.modelInfo[key];

						let selectItem = {
							id: model.contentSet + "|" + model.signFront,
							name: model.name
						}

						newModelInfo.selectList.push(selectItem);
					}

					newModelInfo.selectList = newModelInfo.selectList.sort((a, b) =>
					{
						function fixName(name)
						{
							name = name.toLowerCase();

							if (name.startsWith("university of "))
							{
								name = name.substr("university of ".length);
							}
							if (name.endsWith(" university"))
							{
								name = name.substr(0, " university".length);
							}
							if (name.endsWith(" college"))
							{
								name = name.substr(0, " college".length);
							}

							return name;
						}

						let aName = fixName(a.name);
						let bName = fixName(b.name);

						if (aName > bName)
						{
							return 1;
						}
						if (aName < bName)
						{
							return -11;
						}
						return 0;
					});

					for (let i = 1; i <= 6; i++)
					{
						newModelInfo.selectList.push({ id: "DEMO" + i + "_PLAYLIST|UNKNOWN", name: "Demo " + i });
					}

					state.modelInfo = newModelInfo;
					state.modelInfoLoaded = true;
					state.loadModelInfoStatus = getResetStatus(APPSTATUS_ENUM.SUCCESS);
				}
				else
				{
					state.loadModelInfoStatus = getResetStatus(APPSTATUS_ENUM.FAILED, action.payload.status);
				}
			})
			.addCase(loadModelInfo.rejected, (state, action) =>
			{
				state.loadModelInfoStatus = getResetStatus(APPSTATUS_ENUM.FAILED);
			})

			.addCase(loadUserDeviceGroups.pending, (state, action) =>
			{
				state.loadUserDeviceGroupsStatus = getResetStatus(APPSTATUS_ENUM.LOADING);
			})
			.addCase(loadUserDeviceGroups.fulfilled, (state, action) =>
			{
				if (action.payload?.response)
				{
					state.userDeviceGroups = action.payload?.response;
					state.userDeviceGroupsLoaded = true;
					state.loadUserDeviceGroupsStatus = getResetStatus(APPSTATUS_ENUM.SUCCESS);
				}
				else
				{
					state.loadUserDeviceGroupsStatus = getResetStatus(APPSTATUS_ENUM.FAILED, action.payload.status);
				}
			})
			.addCase(loadUserDeviceGroups.rejected, (state, action) =>
			{
				state.loadUserDeviceGroupsStatus = getResetStatus(APPSTATUS_ENUM.FAILED);
			})

			.addCase(loadSheetsRevisionInfo.pending, (state, action) =>
			{
				state.loadSheetsRevisionInfoStatus = getResetStatus(APPSTATUS_ENUM.LOADING);
			})
			.addCase(loadSheetsRevisionInfo.fulfilled, (state, action) =>
			{
				if (action.payload?.response)
				{
					let newsheetsRevisionInfo = JSON.parse(JSON.stringify(state.sheetsRevisionInfo));

					//console.log("loadSheetsRevisionInfo.before\n" + JSON.stringify(state.sheetsRevisionInfo, null, 2));

					if (action.payload?.response?.sheetRevisions !== undefined)
					{
						for (let sheetId in action.payload?.response?.sheetRevisions)
						{
							if (action.payload?.response?.sheetRevisions?.[sheetId]?.id !== undefined && action.payload?.response?.sheetRevisions?.[sheetId]?.modifiedTime !== undefined)
							{
								newsheetsRevisionInfo.sheetRevisions = {
									...newsheetsRevisionInfo.sheetRevisions,
									[sheetId]: action.payload?.response?.sheetRevisions[sheetId]
								}
							}
						}
					}

					state.sheetsRevisionInfo = newsheetsRevisionInfo;

					//console.log("loadSheetsRevisionInfo.after\n" + JSON.stringify(state.sheetsRevisionInfo, null, 2));

					state.sheetsRevisionInfoLoaded = true;
					state.loadSheetsRevisionInfoStatus = getResetStatus(APPSTATUS_ENUM.SUCCESS);
				}
				else
				{
					state.loadSheetsRevisionInfoStatus = getResetStatus(APPSTATUS_ENUM.FAILED, action.payload.status);
				}

			})
			.addCase(loadSheetsRevisionInfo.rejected, (state, action) =>
			{
				state.loadSheetsRevisionInfoStatus = getResetStatus(APPSTATUS_ENUM.FAILED);
			})

			.addCase(loadTeamFileInfo.pending, (state, action) =>
			{
				state.loadTeamFileInfoStatus = getResetStatus(APPSTATUS_ENUM.LOADING);
			})
			.addCase(loadTeamFileInfo.fulfilled, (state, action) =>
			{
				if (action.payload?.response)
				{
					state.teamFileInfo = action.payload?.response;
					state.teamFileInfoLoaded = true;
					state.loadTeamFileInfoStatus = getResetStatus(APPSTATUS_ENUM.SUCCESS);
				}
				else
				{
					state.loadTeamFileInfoStatus = getResetStatus(APPSTATUS_ENUM.FAILED, action.payload.status);
				}

			})
			.addCase(loadTeamFileInfo.rejected, (state, action) =>
			{
				state.loadTeamFileInfoStatus = getResetStatus(APPSTATUS_ENUM.FAILED);
			})

			.addCase(saveGameData.pending, (state, action) =>
			{
				state.saveGameDataStatus = getResetStatus(APPSTATUS_ENUM.LOADING);
			})
			.addCase(saveGameData.fulfilled, (state, action) =>
			{
				if (action.payload?.response)
				{
					for (let sheetId in action.payload?.response.sheetResults)
					{
						console.log("***sheetId[" + action.payload?.response.env + "]: " + sheetId);
					}

					let newTeamFileInfo = JSON.parse(JSON.stringify(state.teamFileInfo));
					let newsheetsRevisionInfo = JSON.parse(JSON.stringify(state.sheetsRevisionInfo));

					//console.log("saveGameData.before\n" + JSON.stringify(state.sheetsRevisionInfo, null, 2));

					for (let sheetId in action.payload?.response.sheetResults)
					{
						let sheetInfo = action.payload?.response.sheetResults[sheetId];

						/*
							{
							"revision": {
								"id": "7055",
								"modifiedTime": "2024-03-26T18:43:12.906Z"
							},
							"gameDataInfo": {
								"type": "teamData",
								"name": "gamedata",
								"team": "uc",
								"sport": "mbasketball",
								"env": "stage",
								"path": [
								"Drive",
								"uc",
								"mbasketball",
								"gamedata"
								],
								"revision": {
								"id": "7055",
								"modifiedTime": "2024-03-26T18:43:12.906Z"
								},
								"sheetId": "1GWQsu4KLbZRPcHl9JJNd4ZdThuCafc8h17rVUDHL44M",
								"date": "2024-03-26T18:47:21.762Z"
							}
							}						
						*/

						if (sheetInfo?.revision?.id !== undefined && sheetInfo?.revision?.modifiedTime !== undefined)
						{
							newsheetsRevisionInfo = {
								sheetRevisions: {
									...newsheetsRevisionInfo.sheetRevisions,
									[sheetId]: sheetInfo.revision
								}
							}
						}

						if (action.payload?.response.env && sheetInfo.gameDataInfo)
						{
							let env = action.payload?.response.env;
							let gameDataInfo = sheetInfo.gameDataInfo;

							if (!newTeamFileInfo[env].hasOwnProperty(gameDataInfo.team))
							{
								newTeamFileInfo = {
									...newTeamFileInfo,
									[env]: {
										...newTeamFileInfo[env],
										[gameDataInfo.team]: {
											team: gameDataInfo.team,
											sports: {}
										}
									}
								}
							}

							let revObj = {
								"sheetId": gameDataInfo.sheetId,
								"revision": gameDataInfo.revision,
								"date": gameDataInfo.date,
								"type": gameDataInfo.type,
								"name": gameDataInfo.name
							};

							newTeamFileInfo = {
								...newTeamFileInfo,
								[env]: {
									...newTeamFileInfo[env],
									[gameDataInfo.team]: {
										...newTeamFileInfo[env][gameDataInfo.team],
										sports: {
											...newTeamFileInfo[env][gameDataInfo.team].sports,
											[gameDataInfo.sport]: revObj
										}
									}
								}
							}
						}
					}

					state.teamFileInfo = newTeamFileInfo;
					state.sheetsRevisionInfo = newsheetsRevisionInfo;

					//console.log("saveGameData.after\n" + JSON.stringify(state.sheetsRevisionInfo, null, 2));

					state.saveGameDataStatus = getResetStatus(APPSTATUS_ENUM.SUCCESS);
				}
				else
				{
					state.saveGameDataStatus = getResetStatus(APPSTATUS_ENUM.FAILED, action.payload.status);
				}
			})
			.addCase(saveGameData.rejected, (state, action) =>
			{
				state.saveGameDataStatus = getResetStatus(APPSTATUS_ENUM.FAILED);
			})



			.addCase(updatetUserDeviceInfo.pending, (state, action) =>
			{
				state.updatetUserDeviceInfoStatus = getResetStatus(APPSTATUS_ENUM.LOADING);
			})
			.addCase(updatetUserDeviceInfo.fulfilled, (state, action) =>
			{
				if (action.payload?.response?.updatetUserDeviceInfo)
				{
					let newUserDeviceGroups = getInitialState().userDeviceGroups;

					for (let key in state?.userDeviceGroups?.deviceGroups)
					{
						let deviceGroup = JSON.parse(JSON.stringify(state?.userDeviceGroups?.deviceGroups[key]));

						for (let idx in deviceGroup.hardwareIds)
						{
							let hardwareId = deviceGroup.hardwareIds[idx].hardwareId;

							if (action.payload?.response?.updatetUserDeviceInfo.hasOwnProperty(hardwareId))
							{
								deviceGroup.hardwareIds[idx] = action.payload?.response?.updatetUserDeviceInfo[hardwareId];
							}
						}

						newUserDeviceGroups.deviceGroups.push(deviceGroup);
					}

					state.userDeviceGroups = newUserDeviceGroups;

					state.updatetUserDeviceInfoStatus = getResetStatus(APPSTATUS_ENUM.SUCCESS);
				}
				else
				{
					state.updatetUserDeviceInfoStatus = getResetStatus(APPSTATUS_ENUM.FAILED, action.payload.status);
				}
			})
			.addCase(updatetUserDeviceInfo.rejected, (state, action) =>
			{
				state.updatetUserDeviceInfoStatus = getResetStatus(APPSTATUS_ENUM.FAILED);
			})

			.addCase(deleteGameData.pending, (state, action) =>
			{
				state.deleteGameDataStatus = getResetStatus(APPSTATUS_ENUM.LOADING);
			})
			.addCase(deleteGameData.fulfilled, (state, action) =>
			{
				if (action.payload?.response)
				{
					if (state?.teamFileInfo?.[action.payload?.response.env]?.[action.payload?.response.team]?.sports?.[action.payload?.response.sport])
					{
						let newObj = state.teamFileInfo[action.payload?.response.env][action.payload?.response.team].sports;

						delete newObj[action.payload?.response.sport];

						state.teamFileInfo = {
							...state.teamFileInfo,
							[action.payload?.response.env]: {
								...state.teamFileInfo[action.payload?.response.env],
								[action.payload?.response.team]: {
									...state.teamFileInfo[action.payload?.response.env][action.payload?.response.team],
									sports: newObj
								}
							}
						}
					}

					state.deleteGameDataStatus = getResetStatus(APPSTATUS_ENUM.SUCCESS);
				}
				else
				{
					state.deleteGameDataStatus = getResetStatus(APPSTATUS_ENUM.FAILED, action.payload.status);
				}

			})
			.addCase(deleteGameData.rejected, (state, action) =>
			{
				state.deleteGameDataStatus = getResetStatus(APPSTATUS_ENUM.FAILED);
			})



			.addCase(getGameData.pending, (state, action) =>
			{
				state.getGameDataStatus = getResetStatus(APPSTATUS_ENUM.LOADING);
			})
			.addCase(getGameData.fulfilled, (state, action) =>
			{
				if (action.payload?.response)
				{
					state.getGameDataStatus = getResetStatus(APPSTATUS_ENUM.SUCCESS);
				}
				else
				{
					state.getGameDataStatus = getResetStatus(APPSTATUS_ENUM.FAILED, action.payload.status);
				}

			})
			.addCase(getGameData.rejected, (state, action) =>
			{
				state.getGameDataStatus = getResetStatus(APPSTATUS_ENUM.FAILED);
			})

			.addCase(publishGameData.pending, (state, action) =>
			{
				state.publishGameDataStatus = getResetStatus(APPSTATUS_ENUM.LOADING);
			})
			.addCase(publishGameData.fulfilled, (state, action) =>
			{
				if (action.payload?.response?.lines)
				{
					if (action.payload?.response?.lines.length > 0)
					{
						state.publishResultsInfo = {
							lines: action.payload?.response?.lines
						};
					}
					else
					{
						state.publishResultsInfo = {
							lines: ["ERROR: No output"]
						};
						//state.publishResultsInfo = {
						//	lines: FakePublishResults.fakeLines
						//};
					}

					state.publishGameDataStatus = getResetStatus(APPSTATUS_ENUM.SUCCESS);
				}
				else
				{
					state.publishGameDataStatus = getResetStatus(APPSTATUS_ENUM.FAILED, action.payload.status);
				}

			})
			.addCase(publishGameData.rejected, (state, action) =>
			{
				state.publishGameDataStatus = getResetStatus(APPSTATUS_ENUM.FAILED);
			})
	}
});

export const {
	signOutUser,
	clearJWT,
	setContentTab,
	setRefreshToken,
	clearRefreshToken,
	clearPublishResultsInfo,
	resetSignInUserStatus,
	resetAppLoadUserStatus,
	resetGoogleCodeToRefreshTokenStatus,
	resetLoadTeamInfoStatus,
	resetLoadPlaylistInfoStatus,
	resetLoadPublishDataSummaryStatus,
	resetLoadModelInfoStatus,
	resetLoadUserDeviceGroupsStatus,
	resetLoadSheetsRevisionInfoStatus,
	resetLoadTeamFileInfoStatus,
	resetSaveGameDataStatus,
	resetUpdatetUserDeviceInfoStatus,
	resetDeleteGameDataStatus,
	resetGetGameDataStatus,
	resetPublishGameDataStatus,
} = appSlice.actions;

export const selectContentTab = (state) =>
{
	return state.app.contentTab;
}

export const selectJWT = (state) =>
{
	return state.app.jwt;
}

export const selectJWTObj = (state) =>
{
	let requiresAccessToken = (state.app.jwtObj?.capabilities && state.app.jwtObj?.capabilities.includes("publish"));

	return {
		obj: state.app.jwtObj,
		requiresAccessToken
	}
}

export const selectRefreshToken = (state) =>
{
	return state.app.refreshToken;
}

export const selectPublishResultsInfo = (state) =>
{
	return state.app.publishResultsInfo;
}

export const selectSignInUserStatus = (state) =>
{
	return state.app.signInUserStatus;
}

export const selectAppLoadUserStatus = (state) =>
{
	return state.app.appLoadUserStatus;
}

export const selectGoogleCodeToRefreshTokenStatus = (state) =>
{
	return state.app.googleCodeToRefreshTokenStatus;
}

export const selectLoadTeamInfoStatus = (state) =>
{
	return state.app.loadTeamInfoStatus;
}
export const selectLoadPlaylistInfoStatus = (state) =>
{
	return state.app.loadPlaylistInfoStatus;
}
export const selectLoadPublishDataSummaryStatus = (state) =>
{
	return state.app.loadPublishDataSummaryStatus;
}

export const selectLoadModelInfoStatus = (state) =>
{
	return state.app.loadModelInfoStatus;
}

export const selectLoadUserDeviceGroupsStatus = (state) =>
{
	return state.app.loadUserDeviceGroupsStatus;
}

export const selectLoadSheetsRevisionInfoStatus = (state) =>
{
	return state.app.loadSheetsRevisionInfoStatus;
}

export const selectLoadTeamFileInfoStatus = (state) =>
{
	return state.app.loadTeamFileInfoStatus;
}

export const selectSaveGameDataStatus = (state) =>
{
	return state.app.saveGameDataStatus;
}

export const selectUpdatetUserDeviceInfoStatus = (state) =>
{
	return state.app.updatetUserDeviceInfoStatus;
}

export const selectDeleteGameDataStatus = (state) =>
{
	return state.app.deleteGameDataStatus;
}

export const selectGetGameDataStatus = (state) =>
{
	return state.app.getGameDataStatus;
}

export const selectPublishGameDataStatus = (state) =>
{
	return state.app.publishGameDataStatus;
}

export const selectTeamInfo = (state) =>
{
	return {
		data: state.app.teamInfo,
		loaded: state.app.teamInfoLoaded,
	};
}

export const selectPlaylistInfo = (state) =>
{
	return {
		data: state.app.playlistInfo,
		loaded: state.app.playlistInfoLoaded,
	};
}
export const selectPublishDataSummary = (state) =>
{
	return {
		data: state.app.publishDataSummary,
		loaded: state.app.publishDataSummaryLoaded,
	};
}

export const selectModelInfo = (state) =>
{
	return {
		data: state.app.modelInfo,
		loaded: state.app.modelInfoLoaded,
	};
}

export const selectUserDeviceGroups = (state) =>
{
	return {
		data: state.app.userDeviceGroups,
		loaded: state.app.userDeviceGroupsLoaded,
	};
}

export const selectSheetsRevisionInfo = (state) =>
{
	return {
		data: state.app.sheetsRevisionInfo,
		loaded: state.app.sheetsRevisionInfoLoaded,
	};
}

export const selectTeamFileInfo = (state) =>
{
	return {
		data: state.app.teamFileInfo,
		loaded: state.app.teamFileInfoLoaded,
	};
}

export default appSlice.reducer;

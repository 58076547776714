import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useGoogleLogin } from '@react-oauth/google';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';

import
{
	APPSTATUS_ENUM,
	selectJWT,
	selectRefreshToken,
	appSignInUser,
	selectSignInUserStatus,
	resetSignInUserStatus,
	appGoogleCodeToRefreshToken,
} from '../store/appSlice'

function GoogleAuthForm()
{
	const jwt = useSelector(selectJWT);

	const dispatch = useDispatch();

	// scrope for remote script execution
	//scope: 'https://www.googleapis.com/auth/script.external_request https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive',

	const login = useGoogleLogin({
		scope: 'https://www.googleapis.com/auth/spreadsheets.readonly https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/drive.activity',

		isSignedIn: true,
		accessType: "offline",
		responseType: "code",
		prompt: "consent",
		flow: 'auth-code',

		onSuccess: (tokenResponse) =>
		{
			// console.log("useGoogleLogin: " + JSON.stringify(tokenResponse));
			dispatch(appGoogleCodeToRefreshToken({ jwt: jwt, refreshCode: tokenResponse.code }));
		},
	});

	return <>
		<Card>
			<CardContent>
				<Typography variant={"subtitle1"} mb={2}>
					Authorize
				</Typography>
				<Box
					component="form"
					noValidate
					autoComplete="off"
				>
					<Stack spacing={3}>
						<Button onClick={() => { login(); }} variant="contained">
							<>Authorize Google Account</>
						</Button>
					</Stack>
				</Box>
			</CardContent>
		</Card>
	</>;
}

export default GoogleAuthForm
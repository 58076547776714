import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import Alert from '@mui/material/Alert';

import moment from 'moment-timezone';

import
{
	APPSTATUS_ENUM,
	selectJWT,
	selectRefreshToken,

	loadTeamInfo,
	selectTeamInfo,
	selectLoadTeamInfoStatus,
	resetLoadTeamInfoStatus,

	loadPlaylistInfo,
	selectPlaylistInfo,
	selectLoadPlaylistInfoStatus,
	resetLoadPlaylistInfoStatus,
} from '../store/appSlice';

const SPORT_TO_NAME = {
	"football": "Football",
	"wsoccer": "Women's Soccer",
	"msoccer": "Men's Soccer",
	"wbasketball": "Women's Basketball",
	"mbasketball": "Men's Basketball",
	"baseball": "Baseball",
	"softball": "Softball",
	"mhockey": "Hockey",
	"hockey": "Hockey",
	"whockey": "Women's Hockey",
	"wrestling": "Wrestline",
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
		fontSize: 16,
		fontWeight: 700,
		padding: 8,
		paddingLeft: 12,
		paddingRight: 12,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		padding: 8,
		paddingLeft: 12,
		paddingRight: 12,
	},
	[`&.${tableCellClasses.footer}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
		fontSize: 16,
		fontWeight: 700,
		padding: 8,
		paddingLeft: 12,
		paddingRight: 12,
	},
}));

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

const PLAYLIST_DEFAULT_ENV = "prod";
const PLAYLIST_DEFAULT_PUBLISH_PLAYLIST = "publish";
const PLAYLIST_DEFAULT_PLAYLISTS = [PLAYLIST_DEFAULT_PUBLISH_PLAYLIST];

const ERROROBJ_EMPTY = { severity: "none", message: "" };

function LoadingSpinner()
{
	return <>
		<Stack spacing={2} alignItems="center" justifyContent="center" mt={4}>
			<CircularProgress size={40} color="primary" />
			<Typography variant={"subtitle1"} mb={2}>Loading&hellip;</Typography>
		</Stack>
	</>;
};

function LiveScores()
{
	const dispatch = useDispatch();

	const [refreshRunning, setRefreshRunning] = React.useState("");
	const [errorObj, setErrorObj] = React.useState(ERROROBJ_EMPTY);
	const [playlistToShow, setPlaylistToShow] = React.useState(PLAYLIST_DEFAULT_PUBLISH_PLAYLIST);

	const jwt = useSelector(selectJWT);

	const loadTeamInfoStatus = useSelector(selectLoadTeamInfoStatus);
	const loadPlaylistInfoStatus = useSelector(selectLoadPlaylistInfoStatus);

	const teamInfo = useSelector(selectTeamInfo);
	const playlistInfo = useSelector(selectPlaylistInfo);

	const loadInfoRunning = (loadPlaylistInfoStatus.status === APPSTATUS_ENUM.LOADING);

	const allLoaded = (teamInfo.loaded && playlistInfo.loaded);

	React.useEffect(() =>
	{
		let contextObj = { jwt };


		//dispatch(loadTeamInfo({ ...contextObj }));
		dispatch(loadPlaylistInfo({ ...contextObj, env: PLAYLIST_DEFAULT_ENV, playlists: PLAYLIST_DEFAULT_PLAYLISTS }));

	}, []);

	React.useEffect(() =>
	{
		if (loadTeamInfoStatus.status === APPSTATUS_ENUM.SUCCESS)
		{
			dispatch(resetLoadTeamInfoStatus());
		}
		else if (loadTeamInfoStatus.status === APPSTATUS_ENUM.FAILED)
		{
			setErrorObj({ severity: "error", message: loadTeamInfoStatus.errorMsg });

			dispatch(resetLoadTeamInfoStatus());
		}
	}, [loadTeamInfoStatus]);

	React.useEffect(() =>
	{
		if (loadPlaylistInfoStatus.status === APPSTATUS_ENUM.SUCCESS)
		{
			dispatch(resetLoadPlaylistInfoStatus());
		}
		else if (loadPlaylistInfoStatus.status === APPSTATUS_ENUM.FAILED)
		{
			setErrorObj({ severity: "error", message: loadPlaylistInfoStatus.errorMsg });

			dispatch(resetLoadPlaylistInfoStatus());
		}
	}, [loadPlaylistInfoStatus]);

	const isRefreshAllSpinning = () =>
	{
		return (loadInfoRunning && refreshRunning == "all");
	}

	const handleRefreshAll = () =>
	{
		let contextObj = { jwt };

		setRefreshRunning("all");
		setErrorObj(ERROROBJ_EMPTY);

		try
		{
			dispatch(loadPlaylistInfo({ ...contextObj, env: PLAYLIST_DEFAULT_ENV, playlists: PLAYLIST_DEFAULT_PLAYLISTS }));
		}
		catch (err)
		{
			console.log("ERROR: handleRefresh: " + err);
		}
	}

	let games = [];
	let gamesMissing = [];

	if (playlistInfo?.data?.playlists?.[playlistToShow]?.gameIds !== undefined && playlistInfo?.data?.playlists?.[playlistToShow]?.liveScoreUpdates?.games !== undefined)
	{
		for (let gameId in playlistInfo?.data?.playlists?.[playlistToShow]?.liveScoreUpdates?.games)
		{
			let game = JSON.parse(JSON.stringify(playlistInfo.data.playlists[playlistToShow].liveScoreUpdates.games[gameId]));

			let startDate = new Date(game.startTime);

			game.startTimeStr = moment.tz(startDate, timeZone).format("ddd, MMM Do h:mm A");
			game.id = gameId;

			//console.log(JSON.stringify(game, null, 2));

			games.push(game);
		}

		games.sort((a, b) =>
		{
			return a.startTime - b.startTime;
		});

		for (let i = 0; i < playlistInfo?.data?.playlists?.[playlistToShow]?.gameIds.length; i++)
		{
			let gameIdInfo = playlistInfo?.data?.playlists?.[playlistToShow]?.gameIds[i];
			let game = JSON.parse(JSON.stringify(playlistInfo.data.playlists[playlistToShow].liveScoreUpdates.games[gameIdInfo.gameId]));
			if (!game)
			{
				gamesMissing.push(gameIdInfo);
			}
		}
	}

	return <>
		{
			allLoaded ?
				<>
					<div style={{ width: "100%", paddingRight: 10, paddingLeft: 10 }}>
						<div key={"buttons"}>
							<Button onClick={() => { handleRefreshAll(); }} variant="contained">
								<>Refresh</>
								{
									isRefreshAllSpinning() &&
									<>&nbsp;&nbsp;&nbsp;<CircularProgress size={20} style={{ color: 'white' }} /></>
								}
							</Button>
						</div>
						{
							errorObj.severity != "none" &&
							<div key={"error-message"} style={{ marginTop: 10 }}>
								<Alert severity="error" onClose={() => { setErrorObj(ERROROBJ_EMPTY); }}>{errorObj.message}</Alert>
							</div>
						}
						<div key={"maincontent"} style={{ paddingTop: 10, paddingBottom: 20 }}>
							<TableContainer key={"table-container"} component={Paper}>
								<Table key={"table"} sx={{ minWidth: 700 }} aria-label="customized table">
									<TableHead key={"t-header"}>
										<TableRow key={"header"}>
											<StyledTableCell key={"head-date"}>Date</StyledTableCell>
											<StyledTableCell key={"head-sport"}>Sport</StyledTableCell>
											<StyledTableCell key={"head-matchup"}>Matchup</StyledTableCell>
											<StyledTableCell key={"head-status"}>Status</StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody key={"t-body"}>
										{
											games.map((game, i) =>
											{
												let status = <i>Unknown</i>

												if (game.state == "pre")
												{
													status = <i>Upcoming</i>
												}
												else
												{
													if (game?.eventScoreDetails?.line3?.length > 0)
													{
														status = <div>
															<div>{game.eventScoreDetails.line1}</div>
															<div>{game.eventScoreDetails.line2}</div>
															<div>{game.eventScoreDetails.line3}</div>
														</div>
													}
													else if (game?.eventScoreDetails?.line2?.length > 0)
													{
														status = <div>
															<div>{game.eventScoreDetails.line1}</div>
															<div>{game.eventScoreDetails.line2}</div>
														</div>
													}
													else if (game?.eventScoreDetails?.line1?.length > 0)
													{
														status = <div>
															<div>{game.eventScoreDetails.line1}</div>
														</div>
													}
												}

												let awayTeam = game.away.rank > 0 ? <><span style={{ fontSize: "80%" }}>({game.away.rank})&nbsp;</span><strong>{game.away.location}</strong></> : <><strong>{game.away.location}</strong></>;
												let homeTeam = game.home.rank > 0 ? <><span style={{ fontSize: "80%" }}>({game.home.rank})&nbsp;</span><strong>{game.home.location}</strong></> : <><strong>{game.home.location}</strong></>;

												return <TableRow key={game.id}>
													<StyledTableCell key={"body-date" + game.id}>{game.startTimeStr}</StyledTableCell>
													<StyledTableCell key={"body-sport" + game.id}><Tooltip title={game.id}><div>{SPORT_TO_NAME[game.sport]}</div></Tooltip></StyledTableCell>
													<StyledTableCell key={"body-matchup" + game.id}><div className="teams">{awayTeam}<span>&nbsp;at&nbsp;</span>{homeTeam}</div></StyledTableCell>
													<StyledTableCell key={"body-status" + game.id}>{status}</StyledTableCell>
												</TableRow>
											})
										}
									</TableBody>
								</Table>
							</TableContainer>
							{
								gamesMissing.length > 0 &&
								<div>
									<h1>MISSING GAMES</h1>
									{
										gamesMissing.map((game, i) =>
										{
											return <><pre><code>{JSON.stringify(game, null, 2)}</code></pre><br /></>
										})
									}
								</div>
							}
						</div>
					</div >
				</>
				:
				<><LoadingSpinner /></>
		}
	</>;
}

export default LiveScores;

import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

import
{
	APPSTATUS_ENUM,
	selectJWT,
	selectRefreshToken,
	appSignInUser,
	selectSignInUserStatus,
	resetSignInUserStatus,
} from '../store/appSlice'

function SignInForm()
{
	const dispatch = useDispatch();

	const [showPassword, setShowPassword] = React.useState(false);
	const [signInEmailAddress, setSignInEmailAddress] = React.useState("");
	const [signInPassword, setSignInPassword] = React.useState("");
	const [signInError, setSignInError] = React.useState("");

	const signInUserStatus = useSelector(selectSignInUserStatus);

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event) =>
	{
		event.preventDefault();
	};

	React.useEffect(() =>
	{
		if (signInUserStatus.status === APPSTATUS_ENUM.SUCCESS)
		{
			dispatch(resetSignInUserStatus());
		}
		else if (signInUserStatus.status === APPSTATUS_ENUM.FAILED)
		{
			setSignInError(signInUserStatus.errorMsg);
			dispatch(resetSignInUserStatus());
		}
	}, [signInUserStatus]);

	const handleSignInButton = (signInObj) =>
	{
		setSignInError("");
		dispatch(appSignInUser(signInObj));
	}

	return <>
		<Card>
			<CardContent>
				<Typography variant={"subtitle1"} mb={2}>
					Sign In
				</Typography>
				<Box
					component="form"
					noValidate
					autoComplete="off"
				>
					<Stack spacing={3}>
						{
							signInError.length > 0 &&
							<Alert severity="error" onClose={() => { setSignInError(""); }}>{signInError}</Alert>
						}
						<FormControl sx={{ width: '35ch' }} variant="filled">
							<InputLabel htmlFor="filled-adornment-password">E-mail</InputLabel>
							<FilledInput
								id="email-address"
								type='text'
								onChange={(evt) => { setSignInEmailAddress(evt.target.value) }}
								value={signInEmailAddress}
							/>
						</FormControl>
						<FormControl sx={{ width: '35ch' }} variant="filled">
							<InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
							<FilledInput
								id="password"
								type={showPassword ? 'text' : 'password'}
								onChange={(evt) => { setSignInPassword(evt.target.value) }}
								value={signInPassword}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											edge="end"
										>
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								}
							/>
						</FormControl>
						<Button onClick={() =>
						{
							handleSignInButton({ username: signInEmailAddress, password: signInPassword })
						}} variant="contained">
							<>
								{
									signInUserStatus.status === APPSTATUS_ENUM.LOADING &&
									<><CircularProgress size={20} style={{ color: 'white' }} />&nbsp;&nbsp;&nbsp;</>
								}
								<>Sign In</>
							</>
						</Button>
					</Stack>
				</Box>
			</CardContent>
		</Card>
	</>;
}

export default SignInForm
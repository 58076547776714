import './App.css';

import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import
{
	APPSTATUS_ENUM,
	selectJWT,
	selectJWTObj,
	selectRefreshToken,
	appAppLoadUser,
	selectAppLoadUserStatus,
	resetAppLoadUserStatus,
	selectContentTab,
	selectTeamInfo,
	loadTeamInfo,
	selectLoadTeamInfoStatus,
	resetLoadTeamInfoStatus,
	setContentTab,
} from './store/appSlice'

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import Header from './components/Header';
import SignInForm from './components/SignInForm';
import GoogleAuthForm from './components/GoogleAuthForm';
import MainForm from './components/MainForm';
import ManageDevices from './components/ManageDevices';
import LiveScores from './components/LiveScores';
import GamesSummary from './components/GamesSummary';

function LoadingSpinner()
{
	return <>
		<Stack spacing={2} alignItems="center" justifyContent="center" mt={4}>
			<CircularProgress size={40} color="primary" />
			<Typography variant={"subtitle1"} mb={2}>Loading&hellip;</Typography>
		</Stack>
	</>;
};

function App()
{
	const [showAppForms, setShowAppForms] = React.useState(false);

	const dispatch = useDispatch();

	const jwt = useSelector(selectJWT);
	const jwtObj = useSelector(selectJWTObj);
	const contentTab = useSelector(selectContentTab);
	const refreshToken = useSelector(selectRefreshToken);
	const teamInfo = useSelector(selectTeamInfo);

	const appLoadUserStatus = useSelector(selectAppLoadUserStatus);
	const loadTeamInfoStatus = useSelector(selectLoadTeamInfoStatus);

	React.useEffect(() =>
	{
		if (loadTeamInfoStatus.status === APPSTATUS_ENUM.SUCCESS)
		{
			dispatch(resetLoadTeamInfoStatus());
		}
		else if (loadTeamInfoStatus.status === APPSTATUS_ENUM.FAILED)
		{
			//setErrorObj({ severity: "error", message: loadTeamInfoStatus.errorMsg });

			dispatch(resetLoadTeamInfoStatus());
		}
	}, [loadTeamInfoStatus]);

	React.useEffect(() =>
	{
		if (appLoadUserStatus.status === APPSTATUS_ENUM.SUCCESS)
		{
			dispatch(resetAppLoadUserStatus());
			setShowAppForms(true);
		}
		else if (appLoadUserStatus.status === APPSTATUS_ENUM.FAILED)
		{
			dispatch(resetAppLoadUserStatus());
			setShowAppForms(true);
		}
	}, [appLoadUserStatus]);

	React.useEffect(() =>
	{
		dispatch(appAppLoadUser());
	}, []);

	React.useEffect(() =>
	{
		if (jwt && !teamInfo.loaded && loadTeamInfoStatus.status === APPSTATUS_ENUM.IDLE)
		{
			let contextObj = { jwt };

			if (jwtObj.requiresAccessToken)
			{
				dispatch(setContentTab({ value: "gamedata" }));
			}
			else
			{
				dispatch(setContentTab({ value: "summary" }));
			}

			dispatch(loadTeamInfo({ ...contextObj }));
		}

	}, [jwt, teamInfo, loadTeamInfoStatus]);

	return (
		<div key="top" className="top-container">
			<Header />
			<div key="top-container" className="content-container">
				{
					showAppForms &&
					<>
						{
							jwt ?
								<>
									{
										refreshToken || !jwtObj.requiresAccessToken ?
											<>
												{
													teamInfo.loaded ?
														<>
															{
																contentTab === "gamedata" &&
																<MainForm />
															}
															{
																contentTab === "summary" &&
																<GamesSummary />
															}
															{
																contentTab === "devices" &&
																<ManageDevices />
															}
															{
																contentTab === "livescores" &&
																<LiveScores />
															}
														</>
														:
														<>
															<LoadingSpinner />
														</>
												}
											</>
											:
											<><GoogleAuthForm /></>
									}
								</>
								:
								<SignInForm />
						}
					</>
				}
			</div>
		</div>
	);
}

export default App;

import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';

import * as Globals from '../Globals';
import * as Utilities from '../utils/Utilities';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Unpublished';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/material/Alert';

import
{
	APPSTATUS_ENUM,
	selectJWT,
	selectRefreshToken,

	loadModelInfo,
	selectModelInfo,
	selectLoadModelInfoStatus,
	resetLoadModelInfoStatus,

	loadUserDeviceGroups,
	selectUserDeviceGroups,
	selectLoadUserDeviceGroupsStatus,
	resetLoadUserDeviceGroupsStatus,

	updatetUserDeviceInfo,
	selectUpdatetUserDeviceInfoStatus,
	resetUpdatetUserDeviceInfoStatus,

} from '../store/appSlice';

/*
"PRODUCTION": "PRODUCTION",
"PRERELEASE": "PRERELEASE",
"STAGE": "STAGE",
"DEVELOPMENT": "DEVELOPMENT",
*/

let ENVIRONMENTS = [
	"PRODUCTION",
	"PRERELEASE",
	"STAGE",
	"DEVELOPMENT",
];

function LoadingSpinner()
{
	return <>
		<Stack spacing={2} alignItems="center" justifyContent="center" mt={4}>
			<CircularProgress size={40} color="primary" />
			<Typography variant={"subtitle1"} mb={2}>Loading&hellip;</Typography>
		</Stack>
	</>;
};

const ERROROBJ_EMPTY = { severity: "none", message: "" };
const DEVICE_THUMBNAIL_URL = "https://d36vnzryndsjwn.cloudfront.net/assets/prod/signfronts/##signFront##/mobile-thumbnail/image.png";

function DeviceCard({ deviceGroup, deviceItem, index, indexOf })
{
	const dispatch = useDispatch();
	const theme = useTheme();

	return <>
		<Box sx={{
			display: "inline-block",
			bgcolor: theme.palette.primary.main,
			borderRadius: 3,
			alignItems: 'center',
			padding: 2,
			paddingLeft: 3,
			paddingRight: 3,
			minWidth: 180,
		}}>
			{
				indexOf > 1 &&
				<div style={{ width: "100%", textAlign: 'left' }}>
					<Typography color={theme.palette.primary.contrastText} variant={"subtitle"} mb={2}>{(index + 1) + " of " + indexOf}</Typography>
				</div>
			}
			<div style={{ paddingTop: 6, textAlign: 'center', margin: "auto" }}>
				<img style={{ width: 150 }} src={DEVICE_THUMBNAIL_URL.replace("##signFront##", deviceItem.signFront)} />
			</div>
			<div style={{ width: "100%", textAlign: 'center' }}>
				<Typography
					color={theme.palette.text.primary}
					variant={"subtitle"}
					mb={2}
				>
					{deviceItem.name}
				</Typography>
			</div>
			<div style={{ width: "100%", textAlign: 'center', paddingTop: 10 }}>
				<Typography
					color={theme.palette.primary.contrastText}
					variant={"subtitle"}
					style={{ fontStyle: "italic", fontWeight: 700 }}
					mb={2}
				>
					{deviceItem.contentEnv}
				</Typography>
			</div>
		</Box>
	</>;
}

function EditDeviceItemDialog({ editDeviceItem, handleEditDeviceDialogClose, handleEditDeviceDialogApply })
{
	const [contentSet, setContentSet] = React.useState("");
	const [name, setName] = React.useState("");
	const [contentEnv, setContentEnv] = React.useState("");
	const [localDeviceItem, setLocalDeviceItem] = React.useState(undefined);

	const modelInfo = useSelector(selectModelInfo);

	React.useEffect(() =>
	{
		if (editDeviceItem)
		{
			setContentSet(editDeviceItem?.deviceItem?.contentSet + "|" + editDeviceItem?.deviceItem?.signFront);
			setName(editDeviceItem?.deviceItem?.name);
			setContentEnv(editDeviceItem?.deviceItem?.contentEnv);
			setLocalDeviceItem(editDeviceItem);
		}
		else
		{
			setLocalDeviceItem(undefined);
		}

	}, [editDeviceItem]);

	const handleName = (name) =>
	{
		setName(name);
		setLocalDeviceItem({ ...localDeviceItem, deviceItem: { ...localDeviceItem.deviceItem, name } });
	}

	const handleContentSet = (contentSet) =>
	{
		setContentSet(contentSet);

		let eloc = contentSet.indexOf("|");
		if (eloc >= 0)
		{
			setLocalDeviceItem({ ...localDeviceItem, deviceItem: { ...localDeviceItem.deviceItem, contentSet: contentSet.substr(0, eloc), signFront: contentSet.substr(eloc + 1) } });
		}
	}

	const handleContentEnv = (contentEnv) =>
	{
		setContentEnv(contentEnv);
		setLocalDeviceItem({ ...localDeviceItem, deviceItem: { ...localDeviceItem.deviceItem, contentEnv } });
	}

	const handleEditDeviceDialogApplyLocal = () =>
	{
		handleEditDeviceDialogApply(localDeviceItem);
	}

	return <Dialog open={editDeviceItem !== false} fullWidth maxWidth={"sm"}>
		{
			localDeviceItem &&
			<>
				<DialogTitle>Edit Device</DialogTitle>
				<DialogContent>
					<DialogContentText
						key={"Dialog-DialogContentText"}
					>
						{localDeviceItem.deviceGroup.name}
					</DialogContentText>
					<div
						key={"Dialog-header-div"}
						style={{
							width: '100%',
							textAlign: 'center',
							paddingTop: 8,
						}}>
						<div
							key={"Dialog-label-div"}
							style={{ margin: "auto", display: "inline-block" }}>
							<DeviceCard deviceGroup={localDeviceItem.deviceGroup} deviceItem={localDeviceItem.deviceItem} index={localDeviceItem.index} indexOf={localDeviceItem.indexOf} />
						</div>
					</div>
					<Box key={"Box-contentSet"} style={{ paddingTop: 20 }}>
						<FormControl key={"FormControl-contentSet"} fullWidth >
							<InputLabel key={"InputLabel-contentSet"} id="demo-simple-select-label">University</InputLabel>
							<Select
								key={"Select-contentSet"}
								value={contentSet}
								label="selectContent"
								onChange={(event) => { handleContentSet(event.target.value) }}
							>
								{
									modelInfo.data.selectList.map((item, index) =>
									{
										return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
									})
								}
							</Select>
						</FormControl>
					</Box>
					<Box key={"Box-name"} style={{ paddingTop: 20 }}>
						<TextField
							key={"TextField-name"}
							value={name}
							label="Name"
							onChange={(event) => { handleName(event.target.value) }}
							style={{ width: "100%" }}
						/>
					</Box>
					<Box key={"Box-contentEnv"} style={{ paddingTop: 20 }}>
						<FormControl key={"FormControl-contentEnv"} fullWidth>
							<InputLabel key={"InputLabel-contentEnv"} id="demo-simple-select-label">Environment</InputLabel>
							<Select
								key={"Select-contentEnv"}
								value={contentEnv}
								label="ContentEnv"
								onChange={(event) => { handleContentEnv(event.target.value) }}
							>
								{
									ENVIRONMENTS.map((item, index) =>
									{
										return <MenuItem key={item} value={item}>{item}</MenuItem>
									})
								}
							</Select>
						</FormControl>
					</Box>
				</DialogContent>
				<DialogActions key={"Dialog-DialogActions"}>
					<Button key={"Dialog-ButtonClose"} onClick={handleEditDeviceDialogClose}>Cancel</Button>
					<Button key={"Dialog-ButtonApply"} onClick={handleEditDeviceDialogApplyLocal}>Apply</Button>
				</DialogActions>
			</>
		}
	</Dialog>
		;
}

function ManageDevices()
{
	const dispatch = useDispatch();
	const theme = useTheme();

	//console.log("theme: " + JSON.stringify(theme.palette, null, 2));

	const [editDeviceItem, setEditDeviceItem] = React.useState(false);
	const [refreshRunning, setRefreshRunning] = React.useState("");
	const [errorObj, setErrorObj] = React.useState(ERROROBJ_EMPTY);

	const jwt = useSelector(selectJWT);
	const refreshToken = useSelector(selectRefreshToken);

	const loadModelInfoStatus = useSelector(selectLoadModelInfoStatus);
	const loadUserDeviceGroupsStatus = useSelector(selectLoadUserDeviceGroupsStatus);
	const updatetUserDeviceInfoStatus = useSelector(selectUpdatetUserDeviceInfoStatus);

	const modelInfo = useSelector(selectModelInfo);
	const userDeviceGroups = useSelector(selectUserDeviceGroups);

	const loadInfoRunning = (userDeviceGroups.status === APPSTATUS_ENUM.LOADING);

	const allLoaded = (modelInfo.loaded && userDeviceGroups.loaded);

	React.useEffect(() =>
	{
		let contextObj = { jwt };

		dispatch(loadModelInfo({ ...contextObj }));
		dispatch(loadUserDeviceGroups({ ...contextObj }));

	}, []);

	React.useEffect(() =>
	{
		if (updatetUserDeviceInfoStatus.status === APPSTATUS_ENUM.SUCCESS)
		{
			dispatch(resetLoadModelInfoStatus());
		}
		else if (updatetUserDeviceInfoStatus.status === APPSTATUS_ENUM.FAILED)
		{
			setErrorObj({ severity: "error", message: loadModelInfoStatus.errorMsg });

			dispatch(resetUpdatetUserDeviceInfoStatus());
		}
	}, [updatetUserDeviceInfoStatus]);

	React.useEffect(() =>
	{
		if (loadModelInfoStatus.status === APPSTATUS_ENUM.SUCCESS)
		{
			dispatch(resetLoadModelInfoStatus());
		}
		else if (loadModelInfoStatus.status === APPSTATUS_ENUM.FAILED)
		{
			setErrorObj({ severity: "error", message: loadModelInfoStatus.errorMsg });

			dispatch(resetLoadModelInfoStatus());
		}
	}, [loadModelInfoStatus]);

	React.useEffect(() =>
	{
		if (loadUserDeviceGroupsStatus.status === APPSTATUS_ENUM.SUCCESS)
		{
			dispatch(resetLoadUserDeviceGroupsStatus());
		}
		else if (loadUserDeviceGroupsStatus.status === APPSTATUS_ENUM.FAILED)
		{
			setErrorObj({ severity: "error", message: loadUserDeviceGroupsStatus.errorMsg });

			dispatch(resetLoadUserDeviceGroupsStatus());
		}
	}, [loadUserDeviceGroupsStatus]);

	const isRefreshAllSpinning = () =>
	{
		return (loadInfoRunning && refreshRunning == "all");
	}

	const handleRefreshAll = () =>
	{
		let contextObj = { jwt };

		setRefreshRunning("all");
		setErrorObj(ERROROBJ_EMPTY);

		try
		{
			dispatch(loadUserDeviceGroups({ ...contextObj }));
		}
		catch (err)
		{
			console.log("ERROR: handleRefresh: " + err);
		}
	}

	const editDevice = (deviceItemEditContext) =>
	{
		setEditDeviceItem(deviceItemEditContext);
	}

	const handleEditDeviceDialogClose = () =>
	{
		setEditDeviceItem(false);
	}

	const handleEditDeviceDialogApply = (newDeviceItem) =>
	{
		let contextObj = { jwt };

		dispatch(updatetUserDeviceInfo({ ...contextObj, deviceUpdateList: [newDeviceItem.deviceItem] }));

		setEditDeviceItem(false);
	}

	return <>
		<EditDeviceItemDialog editDeviceItem={editDeviceItem} handleEditDeviceDialogClose={handleEditDeviceDialogClose} handleEditDeviceDialogApply={handleEditDeviceDialogApply} />
		{
			allLoaded ?
				<>
					<div style={{ width: "100%", paddingRight: 10, paddingLeft: 10 }}>
						<div key={"buttons"}>
							<Button onClick={() => { handleRefreshAll(); }} variant="contained">
								<>Refresh</>
								{
									isRefreshAllSpinning() &&
									<>&nbsp;&nbsp;&nbsp;<CircularProgress size={20} style={{ color: 'white' }} /></>
								}
							</Button>
						</div>
						{
							errorObj.severity != "none" &&
							<div key={"error-message"} style={{ marginTop: 10 }}>
								<Alert severity="error" onClose={() => { setErrorObj(ERROROBJ_EMPTY); }}>{errorObj.message}</Alert>
							</div>
						}
						<div key={"maincontent"} style={{ paddingTop: 10, paddingBottom: 20 }}>
							{
								userDeviceGroups?.data?.deviceGroups?.length > 0
									?
									<>
										{
											userDeviceGroups?.data?.deviceGroups.map((deviceGroup, i) =>
											{
												return <div key={"deviceGroup-" + i}>
													<Typography variant={"h6"} mb={2} style={{ margin: 0, marginTop: 15 }}>{deviceGroup.name}</Typography>
													<Box sx={{
														display: 'flex',
														flexDirection: 'row',
														p: 1,
														m: 1,
														bgcolor: '#eee',
														borderRadius: 1,
														flexWrap: 'wrap',
													}}
													>
														{

															deviceGroup.hardwareIds.map((deviceItem, j) =>
															{
																let deviceItemEditContext = {
																	deviceItem: JSON.parse(JSON.stringify(deviceItem)),
																	deviceGroup: JSON.parse(JSON.stringify(deviceGroup)),
																	index: j,
																	indexOf: deviceGroup.hardwareIds.length,
																};

																return <>
																	{
																		deviceItem.contentSet ?
																			<>
																				<div style={{ padding: 5 }} key={"deviceItem-" + i + "-" + j}>
																					<Link style={{ cursor: "pointer" }} onClick={() => { editDevice(deviceItemEditContext); }}>
																						<DeviceCard deviceGroup={deviceGroup} deviceItem={deviceItem} index={j} indexOf={deviceGroup.hardwareIds.length} />
																					</Link>
																				</div>
																			</>
																			:
																			<></>
																	}
																</>
															})
														}
													</Box>
												</div>
											})
										}
									</>
									:
									<Typography variant={"h6"} mb={2}>No Displays</Typography>
							}

						</div>
					</div >
				</>
				:
				<><LoadingSpinner /></>
		}
	</>;
}

export default ManageDevices;
